// =========================================================================
// Functions
// =========================================================================

// =========================================================================
// PX - REM
// =========================================================================

/*
 * usage: @include x-rem(font-size, 14px);
 * usage: @include x-rem(margin, 0 12px 2 1.2);
 * usage: @include x-rem(padding, 1.5 24px);
 */
@mixin x-rem($property, $values) {

    $base-font-size: 16px; // should be consistent with your html/body font-size
    $px-values: ();
    $rem-values: ();

    @each $value in $values {

        @if $value == 0 or $value == 0px {
            $px-values: join($px-values, 0);
            $rem-values: join($rem-values, 0);
        } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
            $new-rem-value: $value / $base-font-size;
            $px-values: join($px-values, round($value));
            $rem-values: join($rem-values, #{$new-rem-value}rem);
        } @else {
            $px-values: join($px-values, round($value * $base-font-size));
            $rem-values: join($rem-values, #{$value}rem);
        }

    }

    #{$property}: $px-values;
    #{$property}: $rem-values;

}

// =========================================================================
// Grid
// =========================================================================

$column-width: 60;
$gutter-width: 20;
$columns: 12;

@function gridsystem-width($columns:$columns) {
	@return ($column-width * $columns) + ($gutter-width * $columns);
}

$total-width: gridsystem-width($columns);

$min-width: 999999;
$correction: 0.5 / $min-width * 100;

@mixin column($x,$columns:$columns) {
	display: inline;
	float: left;
	width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns));
	margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}
@mixin push($offset:1) {
	margin-left: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}
@mixin pull($offset:1) {
	margin-right: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}

$total-width: 100%;
