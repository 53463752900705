// =========================================================================
// Buttons
// =========================================================================

.site-logo {

    width: 33.33%;
    float: left;
    @include x-rem(padding, 23px 30px);
    transition: all 0.4s ease-in-out;

    img {
    	max-width: 115px;
    }

}

.shrink {

    .site-logo {
        @include x-rem(padding, 13px 30px);
    }

}
