// =========================================================================
// Typography - Resets
// =========================================================================

p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
}

address {
    font-style: normal;
}

ul,
ol {
    @include list();
}

.heading-main {

    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1.1;
    color: $white;
    @include x-rem(font-size, 26px);
    margin: 25px 0;

    @include breakpointMin(480) {
        margin: 40px 0;
        @include x-rem(font-size, 42px);
    }

    @include breakpointMin(680) {
        margin: 50px 0;
        @include x-rem(font-size, 60px);
    }

    @include breakpointMin(960) {
        margin: 60px 0;
        @include x-rem(font-size, 80px);
    }

}

.heading-light {

    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 1.1;

    @include breakpointMin(680) {
        @include x-rem(margin, 0 0 20px 0);
        @include x-rem(font-size, 18px);
    }

    @include breakpoint(579) {
        @include x-rem(margin, 0 0 15px 0);
        @include x-rem(font-size, 18px);
    }

}

.paragraph-light {

    font-weight: 400;
    letter-spacing: 2px;
    line-height: 1.8;
    @include x-rem(margin, 0 0 8px 0);

    @include breakpointMin(680) {
        @include x-rem(font-size, 18px);
    }

    @include breakpoint(679) {
        @include x-rem(font-size, 18px);
    }

}



// .heading-gradient{

//     font-family: 'Asap', sans-serif;
//     font-weight: 700;
//     font-size: 60px;
//     max-width: 600px;
//     margin: 0 auto 2rem;

//     @supports(-webkit-text-fill-color: transparent){
//         background: linear-gradient(
//             90deg,
//             #39c2c2 0,
//             #d53757 50%,
//             #193b7e 100%
//         );
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//     }
//     @include breakpointMin(680) {
//         font-size: 40px;
//     }

//     @include breakpoint(579) {
//         font-size: 32px;
//     }
// }
