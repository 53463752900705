// =========================================================================
// Buttons
// =========================================================================

.site-nav {

    width: 56.33%;
    float: left;
    display: block;
    z-index: 1;
    @include x-rem(padding, 20px 0);

    @include breakpointMin(980) {
        transition: all 0.4s ease-in-out;
    }

    @include breakpoint(979) {
        display: none;
        width: 100%;
        @include x-rem(top, 50px);
        background: $white;
        @include x-rem(padding, 20px 30px);
    }

    &__item {

        display: inline-block;

        @include breakpoint(979) {
            display: block;
        }

    }

    &__link {

        cursor: pointer;
        display: block;
        color: $color__gray;
        text-transform: uppercase;
        @include x-rem(font-size, 14px);
        @include x-rem(padding, 5px 35px);
        transition: none;

        @include breakpoint(979) {
            @include x-rem(padding, 15px 0);
            @include x-rem(font-size, 16px);
        }

        @include breakpointMin(980) {

            &:first-child {
                @include x-rem(padding, 5px 35px 5px 0);
            }

        }

        @include state() {
            color: $color__primary;
        }

    }

    &__hamburger,
    &__close {

        position: absolute;
        @include x-rem(top, 15px);
        @include x-rem(right, 30px);
        @include x-rem(padding, 0 10px);
        cursor: pointer;
        vertical-align: middle;
        width: auto;
        border: none;
        text-align: center;
        text-decoration: none;
        @include transition(all, .2s, linear);
        background: none;

        @include breakpointMin(980) {
            display: none !important;
        }

        svg {
            vertical-align: middle;
        }

    }

}

.current_page_item a,
.single-case_study .menu-item-86 a,
.single-insight .menu-item-82 a,
.single-service .menu-item-85 a {
    color: $color__primary;
}

.shrink {

    .site-nav {

        @include x-rem(padding, 10px 0);

        @include breakpoint(979) {
            @include x-rem(padding, 10px 30px);
        }

        &__hamburger,
        &__close {

            @include breakpoint(979) {
                @include x-rem(top, 10px);
            }

        }

    }

}
