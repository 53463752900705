// =========================================================================
// Modules - Reusable Modular Parts
// =========================================================================

// =========================================================================
// Utilities
// =========================================================================

.central {
    text-align: center;
    max-width: 820px;
    margin: 0 auto;
}

.brand-bg {
    @include gradient-brand();
}

.mid-bg {
    background: #666666;
}