// =========================================================================
// Site Footer
// =========================================================================

.site-footer {

    background-color: $white;

    &__list {

        @include x-rem(padding, 20px 30px);

        @include breakpointMin(768) {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            @include x-rem(padding, 20px 30px);
        }

        @include breakpoint(767) {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            @include x-rem(padding, 20px 30px 10px 30px);
        }

    }

    &__item {

        display: inline-block;
        @include x-rem(padding, 0 10px);
        color: $white;
        text-transform: uppercase;
        @include x-rem(font-size, 12px);

        @include breakpointMin(1210) {
            @include x-rem(padding, 0 15px);
        }

        @include breakpoint(620) {
            width: 100%;
            display: block;
            @include x-rem(padding, 5px 0);
        }

        a {
            color: $white;

            @include state() {
                text-decoration: underline;
            }

        }

        &:first-child {
            padding-left: 0;
        }

    }

    &__heading {

        @include x-rem(margin, 0 0 15px 0);
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 1.1;

        @include breakpointMin(680) {
            @include x-rem(font-size, 22px);
        }

        @include breakpoint(579) {
            @include x-rem(font-size, 18px);
        }

    }

    &__social {

        @include breakpointMin(768) {
            width: 100%;
            text-align: center;
            @include x-rem(padding, 0 30px 20px 30px);
        }

        @include breakpoint(767) {
            width: 100%;
            text-align: center;
            @include x-rem(padding, 0 30px 20px 30px);
        }

        @include breakpointMin(1140) {
            width: 12%;
            float: right;
            text-align: right;
            @include x-rem(padding, 20px 30px);
        }

    }

    &__meta {
        width: 25%;
        float: left;
        @include x-rem(padding, 15px 30px);
        @include x-rem(font-size, 12px);
        color: $color__gray;

        span {
            display: block;
        }

        @include breakpointMin(900) {
            width: 25%;
        }

        @include breakpointMin(621) {
            width: 30%;
        }

        @include breakpoint(620) {
            width: 100%;
            display: block;
            @include x-rem(font-size, 14px);
            @include x-rem(padding, 10px);
            text-align: center;
        }

    }

    &__sm-text {

        @include x-rem(font-size, 12px);

        @include breakpoint(620) {
            @include x-rem(font-size, 14px);
        }

    }

    &__accred {

        width: 55%;
        float: left;
        text-align: center;

        @include breakpointMin(1040) {
            width: 55%;
            @include x-rem(padding, 25px 30px);
        }

        @include breakpointMin(900) {
            width: 55%;
            @include x-rem(padding, 25px 10px);
        }

        @include breakpointMin(621) {
            width: 45%;
        }

        @include breakpoint(620) {
            width: 100%;
            display: block;
            @include x-rem(padding, 10px);
        }

        @include breakpoint(767) {
            @include x-rem(padding-top, 20px);
        }

        img {

            @include breakpointMin(767) {
                @include x-rem(padding, 0 40px);
                display: inline;
            }

            @include breakpointMin(621) {
                @include x-rem(padding, 0 15px);
                display: inline;
            }

            @include breakpoint(620) {
                display: block;
                @include x-rem(padding, 10px);
                margin: 0 auto;
            }

        }

    }

    &__contact {

        width: 20%;
        float: right;
        @include x-rem(padding, 30px 30px);
        text-align: right;

        @include breakpointMin(900) {
            width: 20%;
        }

        @include breakpointMin(621) {
            width: 25%;
        }

        @include breakpoint(620) {
            width: 100%;
            display: block;
            @include x-rem(padding, 10px);
            text-align: center;
        }

    }

    &__title {
        @include x-rem(font-size, 13px);
        color: $color__gray;
    }

    &__tel {
        display: block;
        @include x-rem(font-size, 16px);
        color: $color__primary;
    }

}
