// =========================================================================
// Social Links
// =========================================================================

.social-links {

    width: 10.33%;
    float: right;
    @include x-rem(padding, 22px 30px 23px 0);
    text-align: right;
    transition: all 0.4s ease-in-out;

    @include breakpoint(979) {
        display: none;
    }

    &__item {

        display: inline-block;

        svg {
            vertical-align: middle;
        }

    }

    &__link {

        @include x-rem(padding, 0 2px);

        @include state() {
            opacity: .7;
        }

    }

}

.shrink {

    .social-links {
        @include x-rem(padding, 12px 30px 13px 0);
    }

}
