// =========================================================================
// Layout - Sections of Page
// =========================================================================

// ===================================
// Wrappers
// ===================================

.row {
    position: relative;
    max-width: 1840px;
    margin: 0 auto;
    @include clearfix();

    &--lg {
        max-width: 1240px;
        padding: 0 1rem;
    }
    &--mid {
        max-width: 920px;
        padding: 0 1rem;
    }
}

.page-wrap {
    background-color: #39C7C7;
    background-image: url(/img/bg-trees.jpg);
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    padding-top: 71px;
    text-align: center;
}

// ===================================
// Spacers
// ===================================

.spacer--top {
    padding-top: 8%;
}

.spacer--bottom {
    margin-bottom: 6%;
}

// ===================================
// Main Logo
// ===================================

.main-logo {

    display: block;

    img {
        display: block;
        max-width: 660px;
        margin: 0 auto;

        @include breakpoint(800) {
            width: 50%;
        }
        @include breakpoint(579) {
            width: 60%;
        }

    }

}

// ===================================
// Video container
// ===================================

.video-container {
    border: 8px solid white;
    margin-top: 2%;
    padding: 0;
}



// ===================================
// Overriding style of Wistia's injected
// 'watch again' button
// ===================================
.wistia_postroll_rewatch {
    height: 80px !important;
    width: 80px !important;
    bottom: calc(46% - 40px) !important;
    left: calc(49% - 40px) !important;
}
.wistia-postroll{
    background-color: transparent !important;
}


.article-header {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpointMin(800) {
        padding-left: 0;
        padding-right: 0;
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
    }

    svg {
        flex-shrink: 0;
        @include breakpoint(800) {
            max-width: 36px;
        }
        @include breakpoint(480) {
            max-width: 28px;
        }
    }

    h1 {
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 900px;
    }
}
