// =========================================================================
// Base - Defaults and Content
// =========================================================================

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
	font-size: 1em;
    line-height: 1.5;
    min-height: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: $baseFont;
    font-weight: 300;
    color: $white;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

a {
    cursor: pointer;
    text-decoration: none;
    @include transition(all, .2s, linear);
}

a:hover {
    outline: none;
}

:focus {
    outline: none;
}

a:active {
    outline: none;
}

a[href^=tel] {
    text-decoration: inherit;
}

::selection {
    color: $white;
    background: $color__primary;;
}

::-moz-selection {
    color: $white;
    background: $color__primary;
}
