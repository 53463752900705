// =========================================================================
// Site Header
// =========================================================================

.site-header {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    background: $white;
}

.shrink.site-header {
    @include box-shadow(0, 4px, 2px -2px, rgba(0, 0, 0, .1));
}
